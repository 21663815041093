<template lang="pug">
  v-dialog(
    :value="showDialog"
    @input="open"
    max-width="656"
    persistent
    overlay-color="#000000"
    overlay-opacity="0.5"
  )
    template(v-slot:activator="{ on }")
      v-list-item(
            v-on="on"
          )
        v-list-item-title Сбросить ЭЦП
    v-card
      v-card-title Сброс пароля ЭЦП работника
      v-card-text
          div(v-if="!isLoading")
            p Вы подтверждаете, что хотите сбросить пароль работнику
            | <b> {{worker.surname}} {{worker.name}} {{worker.patronymic}}</b>?
            p Это действие невозможно отменить. Работнику придет SMS с новым паролем.
          div(v-else)
            p Сброс пароля...
          span(style="color:#E72A00;") {{error}}

          v-row(align-end)
            v-col(cols="12" sm="6")
              v-btn(
                @click="close"
                block
                :disabled="isLoading"
              ) Отмена
            v-col(cols="12" sm="6")
              v-btn(
                @click="resetPass"
                block
                :disabled="isLoading"
              ) Сбросить
</template>

<script>
export default {
  props: {
    worker: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      showDialog: false,
      isLoading: false,
      error: null,
    };
  },

  methods: {
    open() {
      this.showDialog = true;
    },
    close() {
      this.showDialog = false;
    },
    async resetPass() {
      this.error = null;
      try {
        this.isLoading = true;
        await this.$store.dispatch('EMPLOYEE/resetPass', this.worker.id);
        this.close();
        this.$notify({
          group: 'note',
          type: 'info',
          title:
            'Пароль ЭЦП работника успешно сброшен. SMS с паролем отправлен на номер телефона работника.',
          text: '',
        });
      } catch (err) {
        if (err?.response?.data?.message === 'Phone not found in signme DB') {
          this.error = 'Телефон не найден в базе Signme';
        } else {
          this.error =
            'Произошла ошибка сброса пароля. Обратитесь в техническую поддержку.';
        }
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>
