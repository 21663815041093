<script>
import { Line } from 'vue-chartjs';
import { dateTime } from '@/utils/convert/common';

export default {
  extends: Line,
  props: {
    labels: {
      type: [Object, Array],
      default: () => ({}),
    },
    value: {
      type: [Object, Array],
      default: () => ({}),
    },
  },
  data: () => ({
    datasets: [],
    min: Infinity,
    max: 0,
    xLabels: new Set(),
  }),
  mounted() {
    const colors = ['#A393F5', '#28BC00'];

    if (Array.isArray(this.value))
      this.value.map((item, index) =>
        this.convertValueToDataset(item, this.labels[index], colors[index]),
      );
    else this.convertValueToDataset(this.value, this.labels, colors[1]);

    this.renderChart({ datasets: this.datasets }, this.generateOptions());
  },

  methods: {
    convertValueToDataset(item, labels, color) {
      this.datasets.push({
        data: this.processData(item.upperBound),
        label: labels.upperBound,
        hiddenOnLegend: true,
        borderColor: color,
        borderDash: [10, 2],
        borderWidth: 1,
        pointRadius: 1,
        fill: false,
        steppedLine: true,
      });
      this.datasets.push({
        data: this.processData(item.value),
        label: labels.value,
        borderColor: color,
        fill: false,
        lineTension: 0,
        borderWidth: 1,
        pointRadius: 2,
      });
      this.datasets.push({
        data: this.processData(item.lowerBound),
        label: labels.lowerBound,
        hiddenOnLegend: true,
        borderColor: color,
        borderDash: [10, 2],
        borderWidth: 1,
        pointRadius: 1,
        fill: false,
        steppedLine: true,
      });
    },
    processData(data) {
      return data.map(({ date, value }) => {
        if (value && this.min > value) this.min = value;
        else if (value && this.max < value) this.max = value;

        return { x: date, y: value };
      });
    },
    generateOptions() {
      const min = this.min - 30;
      const max = this.max + 30;
      return {
        responsive: true,
        maintainAspectRatio: false,
        tooltips: {
          mode: 'index',
          callbacks: {
            title: (tooltipItem, data) => {
              return dateTime(tooltipItem[0].xLabel);
            },
          },
        },
        scales: {
          xAxes: [
            {
              type: 'time',
              distribution: 'series',
              bounds: 'ticks',
              time: {
                displayFormats: {
                  day: 'D.MM',
                },
              },
              ticks: {
                callback(value, index, values) {
                  return values.length - index;
                },
              },
            },
          ],
          yAxes: [{ ticks: { min, max } }],
        },
        legend: {
          position: 'bottom',
          labels: {
            filter: ({ datasetIndex }, { datasets }) =>
              datasets[datasetIndex].hiddenOnLegend !== true,
          },
        },
      };
    },
  },
};
</script>
