<template lang="pug">
PageLayout(
  title="Работник"
  :loading="!item.id || $root.isLoading('worker')"
  backTooltip="Вернуться к списку работников"
  backRoute="employees:list"
  fluid
  )
  v-col(cols="12" sm="6" v-if="item.id")
    v-card
      v-img(:src="photo.url")
        div.archived(
          v-if="photo.state === 'ARCHIVED'"
        ) Фото архивировано {{photo.archived}}
      v-card-title
        span.mr-3 Личные данные
        v-spacer
        v-menu(offset-y)
          template(v-slot:activator="{ on: menu, attrs }")
            v-btn(
              color="primary"
              depressed
              v-on="{ ...menu }"
            ) Действия
          v-list
            v-list-item(@click="chartsDialog = true")
              v-list-item-title Показать графики давления и пульса
            v-list-item(
              v-if="photo.state !== 'NONE'"
              :disabled="photo.state === 'ARCHIVED' || !$can('ARCHIVE_EMPLOYEE_PHOTO')"
              @click="archivePhotoDialog = true"
            )
              v-list-item-title Архивировать фото
            v-list-item(@click="openEmployeeModal('update')" v-if="$can('UPDATE_EMPLOYEES')")
              v-list-item-title Редактировать
            v-list-item(
                v-if="$can('READ_INSPECTIONS_ALL')"
                :to="{ name: 'inspections:list', query: { workerId: item.id } }"
              )
              v-list-item-title Все осмотры работника
            WorkerResetPassModal(v-if="$can('RESET_PASSWORD')" :worker="data")
      DefinitionList(:value="workerData")

  v-col(v-if="$can('EMP_RD')" cols="12" sm="6")
    v-card.mb-4
      v-card-title Средние медицинские показатели
      DefinitionList(v-if="averageData" :value="averageData")
      template(v-else)
        div.text-center.grey--text Нет данных по средним показателям работника

    v-card
      v-card-title Справки
      v-card-text(v-if="medcertificate.length")
        template(v-for="(certificate, index) in medcertificate")
          v-col.d-flex.pa-0(style="cursor: pointer;" @click="openCertDialog(certificate)")
            v-btn(
              depressed
              height="50"
              width="50"
            )
              v-icon fa-search
            v-col.pa-0.ml-3.d-flex.flex-column.align-self-center(cols="10")
              h3 {{ certificate.reason }}
              div {{certificate.period}}
      v-card-text(v-else) Справки отсутствуют

  v-dialog(v-model="showCertificateDialog" max-width="700")
    v-card
      v-img(v-if="currentCert.url" :src="currentCert.url")
      h2.font-weight-light.pa-5.text-center(v-else) Изображение отсутствует

  v-dialog(v-model="chartsDialog" max-width="700")
    v-card
      v-card-title.d-flex
        span График
        v-btn.ml-auto(@click="chartsDialog = false" text) Закрыть
      v-tabs(v-model="chartsTab" fixed-tabs)
        v-tab(key="pressure") Артериальное давление
        v-tab(key="pulse") Пульс
      v-card-text.pt-6
        v-tabs-items(v-model="chartsTab")
          v-tab-item(key="pressure")
            StatsChart(:value="[measurements.diastolicPressure, measurements.systolicPressure]" :labels="chartsLabels.pressures")
          v-tab-item(key="pulse")
            StatsChart(:value="measurements.pulse" :labels="chartsLabels.pulse")

  v-dialog(v-model="archivePhotoDialog" max-width="450")
    v-card
      v-card-title
        h4.text-center.font-weight-light(
          style="word-break: normal;"
        ) Отправить фото в архив? При следующей авторизации на терминале работнику необходимо будет сделать новое фото.
      v-card-actions.d-flex.justify-center
        v-btn.mr-3(
          @click="archivePhotoDialog = false"
          text
        ) Отмена
        v-btn(
          @click="dispatchArchiveRequest"
          :loading="$root.isLoadingGlobal"
          color="primary"
          :disabled="$root.isLoadingGlobal"
          text
        ) OK

</template>

<script>
import Spinner from '@/components/Spinner';
import DefinitionList from '@/components/DefinitionList';
import WorkerResetPassModal from '@/components/WorkerResetPassModal';
import StatsChart from '@/views/employees/components/StatsChart';
import PageLayout from '@/components/layouts/PageLayout';

import { mapGetters, mapActions } from 'vuex';

export default {
  components: {
    Spinner,
    DefinitionList,
    PageLayout,
    WorkerResetPassModal,
    StatsChart,
    Form: () => import('@/views/employees/components/Form.vue'),
  },

  props: {
    openEmployeeModal: {
      type: Function,
      default: () => {},
    },
  },

  data() {
    return {
      chartsDialog: false,
      chartsTab: 'pressure',
      archivePhotoDialog: false,
      chartsLabels: {
        pressures: [
          {
            upperBound: 'Верхняя граница систолического давления',
            value: 'Систолическое давление', // Верхнее
            lowerBound: 'Нижняя граница систолического давления',
          },
          {
            upperBound: 'Верхняя граница диастолического давления',
            value: 'Диастолическое давление', // Нижнее
            lowerBound: 'Нижняя граница диастолического давления',
          },
        ],
        pulse: {
          upperBound: 'Верхняя граница пульса',
          value: 'Пульс',
          lowerBound: 'Нижняя граница пульса',
        },
      },
      currentCert: {},
      showCertificateDialog: false,
    };
  },

  computed: {
    ...mapGetters('EMPLOYEE', [
      'photo',
      'medcertificate',
      'data',
      'item',
      'measurements',
    ]),
    workerData() {
      if (!this.data) return [];

      return [
        { label: 'ФИО', value: this.data.name },
        { label: 'Дата рождения', value: this.data.dateOfBirth },
        { label: 'Возраст', value: this.data.age },
        { label: 'Пол', value: this.data.gender },
        { label: 'Табельный №', value: this.data.personellNumber },
        { label: 'Телефон', value: this.data.phone },
        { label: 'ВУ', value: this.data.license },
        { label: 'Организация', value: this.data.organization },
        { label: 'Статус', value: this.data.status },
        { label: 'Подпись', value: this.data.hash },
      ];
    },
    averageData() {
      if (!this.item.averages) return null;
      if (!this.item.averages.pressure && !this.item.averages.pulse)
        return null;

      return [
        {
          label: 'АД систолическое, мм рт. ст',
          value: this.item.averages.systolicPressure,
        },
        {
          label: 'АД диастолическое, мм рт. ст',
          value: this.item.averages.diastolicPressure,
        },
        { label: 'Пульс, уд./мин', value: this.item.averages.pulse },
      ];
    },
  },

  methods: {
    ...mapActions('EMPLOYEE', ['archivePhoto']),

    openCertDialog(certificate) {
      this.currentCert = certificate;
      this.showCertificateDialog = true;
    },

    async dispatchArchiveRequest() {
      try {
        await this.archivePhoto();
        this.$notify({
          group: 'note',
          type: 'info',
          title: 'Фото работника успешно архивировано',
          text: '',
        });
      } catch (err) {
        this.$notify({
          group: 'error',
          type: 'error',
          title: 'Произошла ошибка архивации фото работника',
          text: '',
        });
      } finally {
        this.archivePhotoDialog = false;
      }
    },
  },
};
</script>

<style lang="scss">
.archived {
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
  font-weight: 300;
  position: absolute;
  height: 10%;
  max-height: 50px;
  color: rgba(255, 255, 255, 0.7);
  text-align: center;
  bottom: 0;
  display: flex;
  font-size: #{'clamp(#{1rem}, #{-0.875rem} + #{3vw}, #{1.7rem})'};
  align-items: center;
  justify-content: center;
}
</style>
